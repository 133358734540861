import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import ResponsiveAppBar from './navbarComponent';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

export default function TopHeaderComponent(){
    const Img = styled('img')({
        maxWidth: '100%',
        height: '100%',
        padding: '0',
        margin: '0',
      });








      
return(
<>
<ResponsiveAppBar></ResponsiveAppBar>
<Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%' }}>
<Grid container spacing={2}>
    <Grid item xs > 
    <ImageListItem>
          <Img src="banner3.png" alt="banner"  />

          <ImageListItemBar
              sx={{
                background:
                  'linear-gradient(to top, rgba(221, 82, 82,0.7) 0%, ' +
                  'rgba(221, 82, 82,0.3) 40%, rgba(0,0,0,0) 100%)',
              }}
              position="bottom"
              actionIcon={
                <>
                <IconButton
                 href="https://www.facebook.com/groups/1604534933692265/?ref=invite_via_link&invite_short_link_key=g%2Fp_mcWtDDXku1jrnBtr%2F7K04YiZT"
                  sx={{ color: 'blue' }}
                >
                  <Tooltip title="Bogomates USA">
                  <FacebookIcon fontSize= "large" />
                  </Tooltip>
                </IconButton>

                <IconButton
                href="https://www.facebook.com/groups/1378358832846768/"
                  sx={{ color: 'yellow' }}
                >
                  <Tooltip title="Bogomates España ">
                  <FacebookIcon fontSize= "large" />
                  </Tooltip>
                </IconButton>

                <IconButton
                    href="https://www.instagram.com/bogomates.com_espana/"
                      sx={{ color: "white" }}
                    >
                      <Tooltip title="Instagram Bogomates ">
                      <InstagramIcon fontSize= "large" />
                      </Tooltip>
                </IconButton>

                </>
              }
              actionPosition="left"
            />
</ImageListItem>

          <div style={{position:'absolute' , left:221, right:0, top:321}}>
    {/* <Button  className="header" size="large" >Join today!</Button>*/} 
        </div>
      
        </Grid>    
       
</Grid>
</Box>


</>

)

}



const ImageButton = styled(Chip)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiImageBackdrop-root': {
        opacity: 0.15,
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
      },
      '& .MuiTypography-root': {
        border: '4px solid currentColor',
      },
    },
  }));
  
  const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  });
  
  const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  }));
  
  const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  }));
  
  const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  }));
  
  export  function ButtonBaseDemo() {
    const images = [
        {
          url: '../public/banner3.png',
          title: 'Breakfast',
          width: '40%',
        },
     
    ]
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 800, width: '100%' }}>
        {images.map((image) => (
          <ImageButton
            focusRipple
            key={image.title}
            style={{
              width: image.width,
            }}
          >
            <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <Image>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                sx={{
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                {image.title}
                <ImageMarked className="MuiImageMarked-root" />
              </Typography>
            </Image>
          </ImageButton>
        ))}
      </Box>
    );
  }